import { useEffect, useState } from 'react';
import useSWR from 'swr';

import {
  BaseItem,
  InternalLink,
  RootMenuItem,
  SubmenuItem,
  SubmenuItemReference,
} from '@interfaces/Menu';
import { rootMenuQuery, subMenuQuery } from '@lib/queries/sanity/menuV2';
import { cdnClient as client } from '@lib/sanityClient';

const isSubmenuReference = (item: any): item is SubmenuItemReference =>
  (item as SubmenuItemReference).type === 'subMenuItem' && 'ref' in item;

export const useMenuData = (): {
  menuData: RootMenuItem[];
  isLoading: boolean;
} => {
  const [menuData, setMenuData] = useState<RootMenuItem[]>([]);

  const fetcher = async (query: string): Promise<any[]> =>
    await client.fetch(query);

  // fetch all root menu items
  const { data: rootItems, isLoading: rootIsLoading } = useSWR(
    rootMenuQuery,
    fetcher,
    {
      refreshInterval: 3600000, // an hour
    }
  );

  // fetch all submenu items
  const { data: allSubMenuItems, isLoading: subMenuIsLoading } = useSWR(
    subMenuQuery,
    fetcher,
    {
      refreshInterval: 3600000, // an hour
    }
  );

  useEffect(() => {
    // this function handles recursion, overrides and standardization
    const updateSubmenuReferences = async (
      initSubmenuLevel: (SubmenuItemReference | BaseItem | InternalLink)[]
    ) => {
      const finalSubmenuLevel = initSubmenuLevel.map(async (i) => {
        // if item is a reference to another submenu
        if (isSubmenuReference(i) && allSubMenuItems) {
          // find the submenu by ref value in list of all submenu references
          const interim = allSubMenuItems.find(
            (item: SubmenuItem) => item.id === i.ref
          );
          // update possible override values for submenu items
          for (const override of ['description', 'title']) {
            if (i[override]) {
              interim[override] = i[override];
            }
          }
          // for referenced submenus, continue to recursively build tree at items
          // all submenus that are references should in theory always have more items
          return {
            ...interim,
            items: await updateSubmenuReferences(interim.items),
          };
        }
        // if not a reference, just return as is
        // this includes internal links, products, categories, pages, ...etc
        return i;
      });
      // await all and return
      return await Promise.all(finalSubmenuLevel);
    };

    const buildNestedTree = async (data: RootMenuItem[]) => {
      // begin building menu structure by replacing all submenu references with matching item in allSubMenuItems
      const completeMenu = data.map(async (rootItem) => ({
        ...rootItem,
        items: await updateSubmenuReferences(rootItem.items),
      }));

      setMenuData(await Promise.all(completeMenu));
    };
    // once data is fetched, build tree
    if (rootItems && allSubMenuItems) buildNestedTree(rootItems);
  }, [allSubMenuItems, rootItems]);

  return { menuData, isLoading: rootIsLoading || subMenuIsLoading };
};
